import React from 'react';
import { MdOutlineLogout} from "react-icons/md";
import logo from "../assets/logo.png";

const Navbar = () => {
    return (
        
        <div className='project-page-header'>
            <img className = 'project-page-header-Img' src={logo} alt=""/>
            <div className='project-page-header-logout'>
                <MdOutlineLogout size={50}/>
                <p> Logout</p>
                
            </div>

         </div>
       
    );
};

export default Navbar;