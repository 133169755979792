// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  *{\n    margin: 0;\n    padding: 0;\n   \nfont-family: 'Roboto', sans-serif;\n\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    \n}\n", "",{"version":3,"sources":["webpack://./src/settings.css"],"names":[],"mappings":";EAGA;IACI,SAAS;IACT,UAAU;;AAEd,iCAAiC;;IAE7B,mCAAmC;IACnC,kCAAkC;;AAEtC","sourcesContent":["\n  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap');\n\n*{\n    margin: 0;\n    padding: 0;\n   \nfont-family: 'Roboto', sans-serif;\n\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
