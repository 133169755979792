import React from 'react';
import imgSucess from '../assets/check.png';


const Modal = ({message}) => {
    return (
        <div className='modalSuccess'>
            <div className='modal-contenair'>
                
                <h2> {message} </h2>  
                <img src={imgSucess}  alt=''/>  
            </div>
        </div>
        
   
        
    );
};

export default Modal;