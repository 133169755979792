import produce from "immer" ; 

const initialState = {
    focusId: " "
}

export const focusIdReducer = (state = initialState, action)=>{
    const {type , payload} = action;

    switch (type){
        case "SET_FOCUS_ID":
            return produce(state, draft =>{
                draft.focusId = payload

            });
        default:
            return state
    }

};