import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { IFCLoader } from "web-ifc-three/IFCLoader";
import {
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
} from "three-mesh-bvh";
import { MeshLambertMaterial } from "three";
import { AmbientLight, AxesHelper, DirectionalLight, GridHelper, PerspectiveCamera, Scene, WebGLRenderer,Raycaster,BufferGeometry, Vector2 } from "three";
import axios from "axios";
import {
  Alert,
  Backdrop,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  CircularProgress,
  CssBaseline,
  IconButton,
  Toolbar,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography
} from "@mui/material";
const ifcLoader = new IFCLoader() 


const IfcLoaderFile = ({onDataLoaded}) => {


               const dataFetchedRef = useRef(false);
              const threeCanvas = useRef(null);
              const scene = useRef(null);
              const camera = useRef(null);
              const renderer = useRef(null);
              const controls = useRef(null);
            
              const ifcModels = useRef([]);
              const mouse = new THREE.Vector2();
              const raycaster = new THREE.Raycaster();
              raycaster.firstHitOnly = true;
              const {userId, projectName} = useParams();
              const getFilesListUrl = process.env.REACT_APP_HOST+`api/users/${userId}/projects/${projectName}`;
              const [isLoading, setLoading] = useState(false);
              const [isDrawerOpen, setDrawerOpen] = useState(false);
              const [isDialogOpen, setDialogOpen] = useState(false);
             const [isSnackbarOpen, setSnackbarOpen] = useState(false);
             const [ifcLoadingErrorMessage, setIfcLoadingErrorMessage] = useState('')
             const [propertiesModel, setPropertiesModel]= useState([])
              
             


              
          
              
              
             
 
        

       // Create an instance of the IFCLoader and set up the mesh BVH for faster performance
       
              ifcLoader.ifcManager.setWasmPath("../../");
              ifcLoader.ifcManager.setupThreeMeshBVH(computeBoundsTree, disposeBoundsTree, acceleratedRaycast);
                
              
              // Apply some configuration options to the WebIfc API
              ifcLoader.ifcManager.applyWebIfcConfig ({USE_FAST_BOOLS: false,
                  COORDINATE_TO_ORIGIN: false});

 useEffect(() => {
  
            // Object to store the size of the viewport
  
            scene.current = new Scene()
            const size = {
              width: window.innerWidth,
              height: window.innerHeight,
            };

            // Set up the camera (point of view of the user)
            const aspect = size.width / size.height;
            camera.current = new THREE.PerspectiveCamera(75, aspect);
            camera.current.position.z = 15;
            camera.current.position.y = 13;
            camera.current.position.x = 8;

            // Set up the lights of the scene
            const lightColor = 0xffffff;

            const ambientLight = new THREE.AmbientLight(lightColor, 0.5);
            scene.current.add(ambientLight);

            const directionalLight = new THREE.DirectionalLight(lightColor, 1);
            directionalLight.position.set(0, 10, 0);
            directionalLight.target.position.set(-5, 0, 0);
            scene.current.add(directionalLight);
            scene.current.add(directionalLight.target);

            // Set up the renderer, fetching the canvas of the HTML
            renderer.current = new THREE.WebGLRenderer({
              canvas: threeCanvas.current,
              alpha: true,
            });

            renderer.current.setSize(size.width, size.height);
            renderer.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));

            // Create grids and axes in the scene
            const grid = new THREE.GridHelper(50, 30);
            scene.current.add(grid);

            const axes = new THREE.AxesHelper();
            axes.material.depthTest = false;
            axes.renderOrder = 1;
            scene.current.add(axes);

            // Set up the orbit controls (to navigate the scene)
            controls.current = new OrbitControls(
              camera.current,
              threeCanvas.current
            );
            controls.current.enableDamping = true;
            controls.current.target.set(-2, 0, 0);

            // Animation loop
            const animate = () => {
              controls.current.update();
              renderer.current.render(scene.current, camera.current);
              requestAnimationFrame(animate);
            };

            animate();

            // Adjust the viewport to the size of the browser
            const handleResize = () => {
              size.width = window.innerWidth;
              size.height = window.innerHeight;
              camera.current.aspect = size.width / size.height;
              camera.current.updateProjectionMatrix();
              renderer.current.setSize(size.width, size.height);
            };

            window.addEventListener("resize", handleResize);

            const getProperties = async(id)=>{
              await ifcLoader.ifcManager.getSpatialStructure(id)
             


            }

       
            //load ifc files from ifc express server
            const loadFilesFromServer = async()=>{
              try{
                  const response = await axios.get(getFilesListUrl ,
                      {
                          headers: {'accessToken':localStorage.getItem('accessToken')}
                      })
                    
                  const projectList = response.data
                  onDataLoaded(projectList)
                 
                  setIfcLoadingErrorMessage("");
                  setLoading(true);
                  for(let i=0 ; i < projectList.length ; i++){
                    
                    const GetFileUrl = process.env.REACT_APP_HOST+`api/users/${userId}/projects/${projectName}/${projectList[i]}`;
                    
                    const response = await  axios.get(GetFileUrl,{
                      headers: {'accessToken':localStorage.getItem('accessToken'),
                      responseType: 'blob' },
                  })
                  const file = await response.data
                  const blob = new Blob([file]);
                  const ifcURL = URL.createObjectURL(blob);
                  const ifcModel = await new Promise((resolve) => {
                    ifcLoader.load(ifcURL, (model) => {
                      resolve(model);
                    });
                  });
                  
                  const properties = await ifcLoader.ifcManager.getSpatialStructure(ifcModel.modelID)
                  console.log(properties)

                 
                  ifcModels.current.push(ifcModel);
                 
                  scene.current.add(ifcModel);
                

                  }
                  setSnackbarOpen(true);
                  setLoading(false);

                 
                 
            
                          
              }
              catch (err ){
                console.log(err)
            
            }
          
          }
          //to stop instruction getting executed multiple time
          if (dataFetchedRef.current) return;
          dataFetchedRef.current = true;

            loadFilesFromServer();
           
           
           
            

            // Clean up function
            return () => {
              window.removeEventListener("resize", handleResize);
            };
           




          }, []);

            // Helper function to perform raycasting
            const cast = (event) => {

              const bounds = threeCanvas.current.getBoundingClientRect();
  
              const x1 = event.clientX - bounds.left;
              const x2 = bounds.right - bounds.left;
              mouse.x = (x1 / x2) * 2 - 1;
  
              const y1 = event.clientY - bounds.top;
              const y2 = bounds.bottom - bounds.top;
              mouse.y = -(y1 / y2) * 2 + 1;
              raycaster.setFromCamera(mouse, camera.current);
              const intersects = raycaster.intersectObjects(ifcModels.current, true);
              return intersects;
            };
  
            
            // raycasting to detect object on scene
            const preselectedMat = new MeshLambertMaterial({
              transparent: true,
              opacity: 0.6,
              color: 0xff88ff,
              depthTest: false,
            })
            const ifc = ifcLoader.ifcManager;
            let preselectModel = { id: -1 };
            const handleDoubleClick =  (event, material) => {
             // let preselectModel = { id: -1 };
              const found = cast(event)[0];
              let modelId =  preselectModel.id
              if (found) {
  
                modelId = found.object.modelID;
                const index = found.faceIndex;
              const geometry = found.object.geometry;
              const ifc = ifcLoader.ifcManager;
              const id = ifc.getExpressId(geometry, index);
             /* ifc.getSpatialStructure(modelId)
              .then(result=>setPropertiesModel(result ))*/
              
            
              console.log(id);
              console.log( modelId)
              
            
         
                ifc.createSubset({
                  modelID: modelId,
                  ids: [id],
                  material: material || preselectedMat,
                  scene: scene.current,
                  removePrevious: true,
                });
              
  
              }
              else{
                console.log('not found')
                ifc.removeSubset( modelId , material);
              }
            };
            
            
   
      
  return (
  <>
  <div className="loader">
      
     
    
      <canvas className="canvas" ref={threeCanvas} onClick={(event)=>handleDoubleClick(event)} />
      
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        {ifcLoadingErrorMessage ? (
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error loading the IFC File. Check the console for more information.
          </Alert>
        ) : (
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            IFC File loaded successfully!
          </Alert>
        )}
      </Snackbar>

  </div>





  </>
    
     
  );
};

export default IfcLoaderFile;