
import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS } from "./types"
import axios from "axios";
const API_URL = process.env.REACT_APP_HOST+'api/login';

export const login = (email,password)=> async(dispatch)=>{
    try{
        const response = await axios.post(API_URL,{email, password})

    
        dispatch({
              type : LOGIN_SUCCESS,
              payload : response.data});
    } catch(err){
        console.log('rerrer', err);
        dispatch({
            type:LOGIN_FAIL,
            payload : err.response.data.message });

    }
         
 }


 export const logout = () =>(dispatch)=>{
      
       dispatch({
        type:LOGOUT_SUCCESS,
    })
 }
