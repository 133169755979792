
import React, { useRef, useState } from 'react';
import { MdCloudUpload  } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import axios from 'axios';
import Modal from './Modal';
import {MdClose} from  "react-icons/md";
import { useParams } from 'react-router-dom';


const AddProject = ({open, onClose}) => {

    const [nameProject, setNameProject] = useState('')
    const [addressProject, setAddressProject] = useState('');
    const [message, setMessage]= useState('no file selected');
    const [fileList, setFileList] = useState([]);
    const [successfulMessage, setSuccessfulMessage] = useState(null);
    const [messageError, setMessageError] = useState(null)
    const [showModal, setshowModal] = useState(false);
    const {userId} = useParams();
    const AddProjectUrl = process.env.REACT_APP_HOST+`api/users/${userId}/project`;
    console.log(AddProjectUrl)
    

    const filesArray = Array.from(fileList);

    const handleFileChange = (e)=>{
        setFileList(e.target.files)
       
    }

    const handleFileDelete = (filetodelete)=>{
        const newFileList = [...fileList];
        newFileList.splice(filetodelete, 1);
        setFileList(newFileList);
        if(filesArray.length ===0){
            setMessage('no file selected');
        }
    }

    const Files = new FormData()
    Files.append("project_name" ,nameProject)
    Files.append("address", addressProject)
    filesArray .forEach((file) => {
        Files.append(`ifc`, file, file.name);
      });

    const closeForm = ()=>{
        setFileList([]);
        setAddressProject('')
        setNameProject('')
        onClose() }

    const handleNameProjectChange = (e)=>{
        setNameProject(e.target.value);
    }

    const handleAddressProjectChange = (e)=>{
        setAddressProject(e.target.value);
    }

    const handleSubmit = (e)=>{
        console.log("bonour")
      

       axios({
        method:"post",
        url: AddProjectUrl,
        data: Files,
        headers: {"accessToken" : localStorage.getItem('accessToken')}
       })
       .then(response => {
        console.log(response)
        setFileList([]);
        setSuccessfulMessage(response.data);
        setNameProject('');
        setAddressProject('');
        onClose();
        window.location.reload(false);
       
        

        
        
       })

       .catch(err=>{
        console.log(err)
        setMessageError(err.response.data.message);
       })

     
       e.preventDefault();
        
       /*formRef.current.reset();*/
       /* setTimeout(() => {

          
            
        }, 2000);*/
       
        console.log('finie')

    }

   

    if(!open) return null
    return (
        <>
                <div className='add-project'>
                   
            <form action=""  className='add-project-form' >
                    <div className='add-project-form-left'>
                        <div className="add-project-form-left-input" onClick={()=>{document.querySelector('.input-field').click()}}>
                            <input type="file"  onChange = {handleFileChange}className='input-field' hidden multiple />
                            <MdCloudUpload size={80} color='#fff'></MdCloudUpload>
                        </div>
                        {( filesArray.length === 0 ) ? (<p>{message}</p>) :
                        <ul>
                            {Array.from(fileList)
                            .map((file,index) => (
                               <> <li key={index}>{file.name}  <MdDelete onClick={()=>{handleFileDelete(index)}} ></MdDelete></li> </>))}
                            </ul>
                        
                        
                            }
                    </div>
                 
                 
                 
                 
                    <div className='add-project-form-right'>
                        <button className=' close-btn' onClick={closeForm} ><MdClose></MdClose></button>
                        <input required type="text" className='add-project-form-right-input' value={nameProject} placeholder='Project Name' onChange={handleNameProjectChange}/>
                        <input required type="text" className='add-project-form-right-input'  value={addressProject}  placeholder='Project address'  onChange={handleAddressProjectChange}/>
                        {messageError && 
                            <p >{messageError.error}</p>
                        }
                         <button className='add-project-form-btn' type='submit' onClick={handleSubmit}>Submit</button>
                </div>
            
         
           

            
            
            </form>
        </div>
        {showModal && 
        <Modal message={successfulMessage}></Modal>
        }
        </>

       

    );
};

export default AddProject;