import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { FaEdit} from "react-icons/fa";
import { MdLocationPin } from "react-icons/md"




const CardProjects = (props) => {
    return (
        <Card sx={{height:250 ,width:250, maxWidth:345 ,
         position:'relative' ,
         transition:"transform 0.30s ease-in-out",
         ':hover':{
            transform: 'scale3d(1.05, 1.05, 1)',
         },
          boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;'}}>
            <CardContent>
                <Typography variant='h5' component="div" sx = {{  color: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'}}>
                    {props.name}
                </Typography>
                <Typography variant='body2' color="text.secondary"  sx = {{ paddingTop:'20px' , color:'black',display:'flex',flexDirection:'row', alignItems:'center', columnGap:'8px'}}>
                <MdLocationPin color='#00bfb6'></MdLocationPin> {props.address}

                </Typography>
            </CardContent>
            <CardActions sx={{position:'absolute', bottom:'5%'}}>
                <Button size='small' color="success" sx = {{fontWeight:600 }}><NavLink style ={{textDecoration:'none', color:'black'}}className="nav-link-card" to={`/projects/${props.userId}/viewer/${props.projectName}`}>view</NavLink></Button>
                <Button size='small' color="success" sx = {{fontWeight:600,color:"#000000"}}> <FaEdit size={20}></FaEdit>   </Button>
            </CardActions>

        </Card>

    );
};

export default CardProjects;