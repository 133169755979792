
import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS } from "../actions/types"
import produce from "immer" ; 

const initialState = {
    token : localStorage.getItem('token'),
    isAuthenticated : null,
    loading: null,
    user: null,
    errorMessage:null,
}



export default function authReducer(state = initialState , action){
    const {type , payload} = action;

    switch(type){
        case LOGIN_SUCCESS:
            localStorage.setItem('accessToken', payload.accessToken);
            return produce(state, draft =>{
                draft.user = payload;
                draft.isAuthenticated = true
                
            })
        case LOGIN_FAIL:
            localStorage.removeItem('accessToken');
            return produce(state , draft =>{
                draft.user = null;
                draft.isAuthenticated = false;
                draft.errorMessage = payload;
            })
        
        case LOGOUT_SUCCESS:
            localStorage.removeItem('accessToken');
            return produce(state , draft =>{
                draft.user = null;
                draft.isAuthenticated = false;
                draft.errorMessage = null;
            })
        default:
            return state;

          

    }
}