
import React, { useEffect, createRef, useState } from "react";

import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Color } from "three";
import {
  Alert,
  Backdrop,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  CircularProgress,
  CssBaseline,
  IconButton,
  Toolbar,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  FolderOpenOutlined,
  CompareArrowsSharp,
  HelpOutline,
  GitHub
} from "@mui/icons-material";
import IfcLoaderFile from "../Components/IfcLoaderFile";
import AddInformationModal from "../Components/AddInformationModal";


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
   fontFamily:" 'Roboto', sans-serif",
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginRight: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  fontFamily:" 'Roboto', sans-serif",
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    fontFamily:" 'Roboto', sans-serif",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  fontFamily:" 'Roboto', sans-serif",
 
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const Viewer = () => {

    const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [IfcFile, setIfcFile] = useState(['banane', 'voiture', 'maison']);
  const [openModal, setOpenModal]= useState(false)
  

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const handleDataLoaded = (data)=>{
    setIfcFile(data)
  }

    return (
        
        <Box sx={{ display: 'flex'}}>
        <AddInformationModal open={openModal} onclose={()=>setOpenModal(false)}/>
        <CssBaseline />
        <AppBar sx = {{backgroundColor:'#ECF0F3' , height:'10%' , color:'black'}} position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{fontWeight: 600}}>
              Fake House 3D model
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            ...(!open && { display: 'none' }),
            position:'relative',
            backgroundColor:'#ECF0F3',
            width: drawerWidth,
            flexShrink: 0,
            
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },

            
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader sx={{backgroundColor:'#ECF0F3', height:'10%'}}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />

          
          
          <TreeView  
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto',backgroundColor:'#ECF0F3', position:'relative' , display:'flex', flexDirection:'column', gap:'20px', fontFamily:"'Roboto', sans-serif",
      fontWeight:700}}
    >
        <Typography sx={{display:'flex', justifyContent:'center', alignItems:'center', paddingTop:'10px',fontWeight:700}}>
            Models
          </Typography>
         {  IfcFile.map((item, index) => (
     <TreeItem key={index} nodeId={`node-${index}`} label={item} sx={{paddingTop:'10px' , fontSize:30}} >
     <TreeItem nodeId=""  label={
        <React.Fragment>
        <Typography sx={{ marginRight: 1, display:'flex', justifyContent:'center', alignItems:'center', gap:'5px'}} onClick={()=>setOpenModal(true)}> <AddCircleIcon sx={{ fontSize: 18 }} /> Add new information </Typography>
        
      </React.Fragment>} />
     
   </TreeItem>))}
        </TreeView>
        </Drawer>
        <Main open={open} sx={{marginRight:'0 px', display:'flex', alignItems:'center', justifyContent:'center'}} >
         

          <IfcLoaderFile onDataLoaded={handleDataLoaded} />

         
                
      </Main>
    </Box>
    );
};

export default Viewer;
