import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {configureStore} from "@reduxjs/toolkit";

import {Provider} from 'react-redux';
import authReducer from "../src/reducers/auth"
import { combineReducers} from 'redux';
import { focusIdReducer } from './reducers/focusIdReducer';

const allReducers = combineReducers({
  auth: authReducer,
  focusId : focusIdReducer
});
const store = configureStore({reducer: allReducers},);
 /* window.REDUX_DEVTOOLS_EXTENSION_ && window.REDUX_DEVTOOLS_EXTENSION_(),*/


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
