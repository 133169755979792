import React, { useState, useEffect} from 'react';
import logo from "../assets/logo.png";
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import {login} from "../actions/auth";
import { Navigate } from 'react-router-dom';


const Login = () => {

   

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading , setLoading] = useState(false)
    const isAuthenticated = useSelector(state=>state.auth.isAuthenticated);
    const user = useSelector(state=>state.auth.user);
    const [errorMessage,setErrorMessage] = useState('') ;
   
    const state = useSelector(state=>state.auth);
 


    const handleEmailChange = (e)=>{
       
        setEmail(e.target.value);
       
     };
     
     const handlePasswordChange = (e)=>{
         setPassword(e.target.value);
     };

     const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true)
        if(validator.isEmail(email)){
            
            dispatch(login(email, password))
            .then(()=>{
                
                if(!isAuthenticated){
                    setLoading(false)
                    setErrorMessage('les informations de connexions que vous avez fournies sont incorrectes');
                   
                }else{
                    navigate("/projets");
                   
                }
                
            })
            .catch(()=>{
                setLoading(false);
                setErrorMessage('une erreur s\'est produite lors de la tentative de connexion');
            })
        }else{
            setLoading(false);
            setErrorMessage('l\'email que vous avez fourni n\'est pas valide');
        }

      
     }


     if (isAuthenticated) {
        return <Navigate to={`/projects/${user.id}`}/>;
      }
    return (

        <>
        <div className='home-class'>
            <img className = 'HomePageImg' src={logo} alt=""/>
            <div className='login-form'>
        <h1>CONNEXION</h1>
        <form action="" className='login-form-classname' >
    
            <div className='login-label-input-classname'>
                <label className='form-label'></label>
                <input type="email" className='form-input' value={email} onChange={ handleEmailChange} placeholder='Email'/>
            </div>
            <div className='login-label-input-classname'>
                <label className='form-label'></label>
                <input type="password" className='form-input' value={password} onChange={handlePasswordChange } placeholder='Mot de passe'/>
                
            </div>
            {errorMessage && <span className='login-label-input-classname' id='error'>{errorMessage}</span>}
            

            <button className='login-button-validation' onClick={ handleSubmit}> CONNEXION </button>

            <p>Pas encore de compte ?   <NavLink to="/register">
                    Inscrivez-vous 
                </NavLink></p>

        </form>
       

        
      </div>
    </div>

        </>
 
    );
};

export default Login;