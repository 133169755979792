import React from 'react';
import { BrowserRouter, Route , Routes } from "react-router-dom";
import Register from './Pages/Register';
import Login from './Pages/Login';
import Projects from './Pages/Projects';
import IfcLoaderFile from './Components/IfcLoaderFile';
import Viewer from './Pages/Viewer';

const AllRoutes = () => {
    return (
        <div>
        <BrowserRouter>
            <Routes>
                <Route element={< Viewer/>} path='/projects/:userId/viewer/:projectName'/>
                <Route element = {<Register/>} path = '/register'/>
                <Route element = {<Login/>} path = '/'/>
                <Route element = {<Projects/>} path = '/projects/:userId'/>
                <Route element = {<IfcLoaderFile />} path = '/loader'/>
                <Route element = {< Viewer  />} path = '/viewer'/>
            </Routes>
        </BrowserRouter>
            
        </div>
    );
};

export default AllRoutes;