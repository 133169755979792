import React, { useState } from 'react';
import validator from 'validator';
import Modal from '../Components/Modal';
import axios from 'axios';
import {useNavigate} from "react-router-dom"
import logo from "../assets/logo.png";
import { NavLink } from 'react-router-dom';

const checkPassword = (a,b)=>{
    if(a === b){
        return true
    }
}

const Register = () => {

const Navigate = useNavigate()
const [name,setName]= useState('');
const [surname, setSurname] = useState('');
const [email ,setEmail]= useState('');
const [password,setPassword] = useState('');
const [confPassword , setConfPassword] = useState('');
const [radioChecked , setRadioChecked] = useState(false);
const ApiUrl = process.env.REACT_APP_HOST+"api/register";
const [submited, setSubmited] = useState(false);
const [showModal, setshowModal] = useState(false);
const [errorEmail, setErrorEmail] = useState("");
const [passwordError, setPassworError] = useState('');
const [radioCheckedError , setRadioCheckedError] = useState('');

const toggle = () =>{
    setshowModal(!showModal);
}

const handleNameChange = (e)=>{
    setName(e.target.value);
};

const handleSurnameChange = (e)=>{
    setSurname(e.target.value);
};

const handleEmailChange = (e)=>{
   setEmail(e.target.value);
  
};

const handlePasswordChange = (e)=>{
    setPassword(e.target.value);
};

const handleConfPasswordChange = (e)=>{
    setConfPassword(e.target.value);
};

const handleCheckboxChange = ()=>{
    setRadioChecked(!radioChecked);
};





const handleSubmit = (e) =>{

    if(validator.isEmail(email)){
        if(checkPassword(password,confPassword)){
            if(radioChecked){
                axios.post(ApiUrl ,{
                    name: name,
                    surname : surname,
                    email: email,
                    password : password,
                    role_id : 2
                })
                .then(res =>{
                    console.log(res);
                    setshowModal(true)
                    setTimeout(()=>{
                        setshowModal(false);
                       
                    } ,2000)
                    setSubmited(true);
                    Navigate('/');
                }).catch( err =>{
                    console.log(err);
                    console.log('helo');
                    const errorMessage = err.response.data.message;
                    setErrorEmail('Cet email est deja utilisé');
                }
                )
               

                
            }else(
                setRadioCheckedError('Veuillez cocher la politique de confidentialité')
            )
        }else(
            setPassworError('Les mots de passe ne correspondent pas')
        )

    }else(
        setErrorEmail('Verifier votre email')
    )


  

    
       

    e.preventDefault();
}





    return (
        <>
        <div className='home-class'>
            <img className = 'HomePageImg' src={logo} alt=""/>
        

        <div className='register-form'>
            <h1>INSCRIPTION</h1>
            <form action="" className='form-classname' >
                <div className='label-input-classname'>
                    <label className='form-label'></label>
                    <input type="text" className='form-input' value={name} onChange={handleNameChange}  placeholder='Nom'/>
                </div>
                <div className='label-input-classname'>
                    <label className='form-label'></label>
                    <input type="text" className='form-input' value={surname} onChange={handleSurnameChange}  placeholder='Prenom'/>
                </div>
                <div className='label-input-classname'>
                    <label className='form-label'></label>
                    <input type="email" className='form-input' value={email} onChange={handleEmailChange} placeholder='Email'/>
                    <span style={{fontWeight: 'bold',color: 'red', }}>{errorEmail}</span>
                </div>
                <div className='label-input-classname'>
                    <label className='form-label'></label>
                    <input type="password" className='form-input' value={password} onChange={handlePasswordChange} placeholder='Mot de passe'/>
                </div>
                <div className='label-input-classname'>
                    <label className='form-label'></label>
                    <input type="password" className='form-input' value={confPassword} onChange={handleConfPasswordChange} placeholder='Confirmer votre mot de passe'/>
                    <span style={{fontWeight: 'bold',color: 'red', }}>{passwordError}</span>
                </div>
                <div className='checkbox-content'>
                    <input type="checkbox" className='form-input' checked={radioChecked} onChange={handleCheckboxChange}/>
                    <label className='form-label'>J'accepte la politique de confidentialité</label>   
                </div>
                <span style={{fontWeight: 'bold',color: 'red', fontSize:'10px'}}>{radioCheckedError }</span>

                <button className='button-validation' onClick={ handleSubmit}> INSCRIPTION </button>

                <p>Vous avez deja un compte?  <NavLink to="/">
                    Connectez-vous
                </NavLink></p>

            </form>
           

            
        </div>

        </div>

        {showModal && 
        <Modal message="Sign up ok"></Modal>
        }
        </>

    );
};

export default Register;