import React, { useEffect } from 'react';
import AddProject from '../Components/AddProject';
import { useState } from 'react';
import { IoIosAddCircle} from "react-icons/io";
import CardProjects from '../Components/CardProjects';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Navbar from '../Components/Navbar';






const Projects = () => {
    const [data , setData] = useState([]);
    const [open , setOpen] = useState(false);
    const {userId} = useParams();
    const [message , setMessage] = useState('');
    const [messageError , setMessageError]=('')
  

   

    const API_URL_GET_PROJECTS = process.env.REACT_APP_HOST+'api/users/'+userId+'/projects';
  
    
   useEffect(()=>{
        axios.get(API_URL_GET_PROJECTS,  {
            headers:{"accessToken" : localStorage.getItem('accessToken')}
        })
        .then(response =>{
            if (response.data.length ===0){
                setMessage('No project created yet');
               
                
            }else{
                setData(response.data)
                
            }

            
        })
        .catch(err=>{
            setMessageError(err.data.message)
            
        })

    }, [open, message, API_URL_GET_PROJECTS])
    return (
        <>
         <div className=' project-page'>
            
            <Navbar></Navbar>

            <div className='project-page-content'>
                <div className="project-page-content-display">
                   
                        <h1>MY PROJECTS</h1>
                            {message.length > 0 ?
                             (
                                 <div className='project-page-content-infos'>
                                        <h1>{message}</h1>
                                 </div>)
                                 
                                 :  <div className='project-page-content-infos'>
                                           <ul className='project-page-content-card'>
                                        {Array.isArray(data) && data.map((project, index)=> 
                                        (<li key={index}><CardProjects  name={project.project_name}  address = {project.address} userId = {userId} projectName={project.project_name}></CardProjects></li>))
                                        }
                          
                                        </ul>
                                  </div>
                               }

                            <div className="project-page-content-add" onClick={()=>setOpen(true)}>
                                <IoIosAddCircle size={50}></IoIosAddCircle>
                                <p>Add new Project</p>
                            </div>
                        
                        
                          

                       
                </div>
                
                <AddProject open={open} onClose = {()=>setOpen(false)}></AddProject>


            </div>

        </div>
        </>

    );
};

export default Projects;