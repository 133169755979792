import React, { useState } from 'react';
import {MdClose} from  "react-icons/md";
import { MdCloudUpload  } from "react-icons/md";
import { MdDelete } from "react-icons/md";

const AddInformationModal = ({open , onclose}) => {

    const [informationName, setInformationName] = useState('');
    const [informationType, setInformationType] = useState('');
    const [fileList, setFileList] = useState([]);
    const [EntityId, setEntityId]=useState();
    const [message, setMessage] = useState();
    


    const filesArray = Array.from(fileList);
    //handle input file
    const handleFileChange = (e)=>{
        setFileList(e.target.files)
       
    }

    const handleFileDelete = (filetodelete)=>{
            const newFileList = [...fileList];
            newFileList.splice(filetodelete, 1);
            setFileList(newFileList);
            if(filesArray.length ===0){
                setMessage('no file selected');
            }
    }

    const handleEntityId = (e)=>{
        setEntityId(e.target.value)
    }

    
    const handleInformationName = (e)=>{
        setInformationName(e.target.value)
    }

    const handleInformationType = (e)=>{
        setInformationType(e.target.value)
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        onclose();
    }

    const closeForm = ()=>{
       
        setInformationName('')
        setInformationType('')
        onclose() }

    if(!open) return null

    return (
    
        <div className="AddInformationModal">
        <div className={informationType === 'pdf'? "AddInformationModal-content": "AddInformationNopdf"}>
        
        
          
        {informationType === 'pdf'&& (
                <div className='AddInformationModal-left'>
                <div className="add-project-form-left-input" onClick={()=>{document.querySelector('.input-field').click()}}>
                    <input type="file"  onChange = {handleFileChange}className='input-field' hidden multiple />
                    <MdCloudUpload size={80} color='#fff'></MdCloudUpload>
                </div>
                {( filesArray.length === 0 ) ? (<p>{message}</p>) :
                <ul>
                    {Array.from(fileList)
                    .map((file,index) => (
                       <> <li key={index}>{file.name}  <MdDelete onClick={()=>{handleFileDelete(index)}} ></MdDelete></li> </>))}
                    </ul>
                
                
                    }
            </div>
        )}
      
          <form onSubmit={handleSubmit} className={informationType === 'pdf'? "addInformation-right": "AddInformation-right-Nopdf"}>
            <button className='close-btn-right' onClick={closeForm }><MdClose ></MdClose></button>
        
            
                            <h2>Add new information</h2>
                        <div className="addInformation-form-label">
                        <input required type="number" className='addInformation-input' value={EntityId} placeholder='EntityId...' onChange={handleEntityId}/>
                        <label> </label>
                        </div>
                        <div className="addInformation-form-label">
                        <input required type="text" className='addInformation-input' value={informationName} placeholder='Information name...' onChange={handleInformationName}/>
                        <label> </label>
                        </div>
                      
                        <select
                                className='addInformation-input'
                                 id="informationType"
                                 value={informationType}
                                 onChange={handleInformationType}
                            >
                                <option value="">Information type</option>
                                <option value="type1">Type 1</option>
                                <option value="type2">Type 2</option>
                                <option value="pdf">Type 3</option>
                            </select>
                         <button className='addInformation-btn' type='submit'>Add</button>
                
  
        
  
            
          </form>
         
        </div>
      </div>
    );
};

export default AddInformationModal;